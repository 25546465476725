import type { NodeSearchResult } from "@stoplight/elements-dev-portal";
import {
  Search as ElementsSearch,
  useGetNodes,
} from "@stoplight/elements-dev-portal";
import * as React from "react";
import { ReactComponent as SearchIcon } from "./images/Search.svg";

export type SearchProps = {
  projectIds: string[];
};

export const Search = ({ projectIds }: SearchProps) => {
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const { data } = useGetNodes({
    search,
    projectIds,
  });

  const handleClose = () => {
    setOpen(false);
    setSearch("");
  };

  const handleClick = (data: NodeSearchResult) => {
    // TODO: Figure out which react router function to replace window.location mutation
    // history.push(`/${data.slug}`);
    window.location.href = `/${data.slug}`;
  };

  return (
    <div className="stoplight-search">
      <SearchIcon onClick={() => setOpen(true)} />
      <input
        height={150}
        placeholder="Search..."
        style={{ color: "white" }}
        onFocus={() => setOpen(true)}
      />
      <ElementsSearch
        search={search}
        onSearch={setSearch}
        onClick={handleClick}
        onClose={handleClose}
        isOpen={open}
        searchResults={data}
      />
    </div>
  );
};
