import { StoplightProject } from "@stoplight/elements-dev-portal";
import { QueryClient, QueryClientProvider } from "react-query";

import "@stoplight/elements-dev-portal/styles.min.css";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Search } from "./Search";
import { ReactComponent as Logo } from "./images/WhispirLogo.svg";

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <Router>
        <QueryClientProvider client={queryClient}>
          <div className="stoplight-header">
            <div className="stoplight-lnb">
              <Logo />
            </div>
            <Search projectIds={["cHJqOjExMTU5Mw"]} />
          </div>
          <div className="stoplight-main">
            <StoplightProject projectId="cHJqOjExMTU5Mw" />
          </div>
        </QueryClientProvider>
      </Router>
    </div>
  );
}

export default App;
